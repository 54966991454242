import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/conference/promo"

    return (
        <Layout pageTitle="Conference & Awards - Templates">
            <Row>
                <Col>
                    <Link to="/conference">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Use these images to promote and share the Women's
                        Business Conference & Awards on social media.
                    </p>
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="I've Booked My Ticket"
                filename="booked-my-ticket"
                text="Insert your own photo using the template below. Use the caption to tell your audience why you are excited about attenting the Women's Business Conference & Awards."
                horizontalHref="https://www.canva.com/design/DAFHIAngDPg/t0G4v8Lnov-UyMFQfEAmGA/view?utm_content=DAFHIAngDPg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                squareHref="https://www.canva.com/design/DAFogJuTxVs/xQNarg0O-1cLoNDasPHS4w/view?utm_content=DAFogJuTxVs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />
            <ImageDownload
                root={root}
                name="Conference Countdown"
                filename="conference-countdown"
                text="Use this template to create countdown posts for social media."
                horizontalHref="https://www.canva.com/design/DAFLoPIxVCo/_rdCLHex3LEa5CL_rDRC7w/view?utm_content=DAFLoPIxVCo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                squareHref="https://www.canva.com/design/DAFogYU6Zg0/1rIjzIP8jiTnaEX2m2rkTQ/view?utm_content=DAFogYU6Zg0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />
             
        </Layout>
    )
}

export default IndexPage
